<template>
  <div class="welcome-video">
    <iframe
      class="wistia_embed"
      name="wistia_embed"
      :src="wistiaUrl"
      title="Wistia video player"
      allowtransparency="true"
      frameborder="0"
      scrolling="no"
      allowfullscreen
      mozallowfullscreen
      webkitallowfullscreen
      oallowfullscreen
      msallowfullscreen
    ></iframe>
    <br>
    <b-button
      class="btn btn-h3-blue btn-h3-small"
      type="button"
      v-if="displayButton"
      @click="$emit('close')">
      End Video
    </b-button>
  </div>
</template>

<script>
  import BreakpointMixin from '@/mixins/BreakpointMixin';

  export default {
    name   : 'WelcomeVideoModal',
    mixins : [
      BreakpointMixin,
    ],
    data() {
      return {
        wistiaUrl : 'https://fast.wistia.net/embed/iframe/' +
          '1e5hlaihyx?autoPlay=true',
        displayButton : false,
      }
    },
    mounted() {
      const that = this;
      setTimeout(() => that.displayButton = true, 3000);
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/settings";

  .close-icon {
    position: absolute;
    top: -7.5px;
    right: 7.5px;
    background-color: #fff;
    border: 1.5px solid #fff;
    border-radius: 1rem;
    color: red;
    cursor: pointer;
    filter: drop-shadow(-2px 2px 2px rgba(0,0,0,0.7));
    font-size: 1rem;
  }

  .welcome-video {
    text-align: center;
  }

  /* Extra Small devices */
  @media only screen and (max-width: $xs-max) {
    .wistia_embed {
      width: 300px;
      height: 200px;
    }
  }

  /* Small devices (portrait tablets and large phones) */
  @media only screen and (min-width: $sm-min) {
    .wistia_embed {
      width: 400px;
      height: 200px;
    }
  }

  /* Medium devices (landscape tablets) */
  @media only screen and (min-width: $md-min) {
    .wistia_embed {
      width: 600px;
      height: 400px;
    }
  }

  /* Large devices (laptops/desktops) */
  @media only screen and (min-width: $lg-min) {
    .wistia_embed {
      width: 700px;
      height: 500px;
    }
  }

  /* Extra large devices (large laptops and desktops) */
  @media only screen and (min-width: $xl-min) {
    .wistia_embed {
      width: 800px;
      height: 500px;
    }
  }
</style>
