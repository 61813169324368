<template>
  <div>
    <!-- Free Trial Notif -->
    <AppNotifBar
      :allowClose="false"
      v-if="subscription.trial === 1 && notif.show">
      {{ notif.content }}
    </AppNotifBar>

    <AppSidebar
      :startTour="startTour"
      @openSwitchUser="openSwitchUser"
    />
    
    <transition name="component-fade" mode="out-in">
      <router-view />
    </transition>

    <AppLoader :isLoading="loading" />

    <!-- Modal -->
    <modals-container :key="$route.fullPath" />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import moment from 'moment-timezone';

  import SubscriptionMixin from '@/mixins/SubscriptionMixin';
  import ParentsPinModal from '@/components/pincode/PinModal';
  import WelcomeVideoModal from './DashboardPage/WelcomeVideoModal';
  import SwitchUserModal from '@/components/common/SwitchUserModal';

  export default {
    head : {
      title : {
        inner      : 'H3',
        separator  : '|',
        complement : 'Healthy Hiphop',
      },
    },
    mixins : [
      SubscriptionMixin,
    ],
    data() {
      return {
        startTour : false,
        loading   : false,
        notif     : {
          show    : false,
          content : null,
        },
      }
    },
    computed : {
      ...mapGetters({
        subscription : 'subscription/subscription',
        user         : 'user/user',
        profile      : 'profile/info',
      }),
    },
    components : {
      'AppSidebar'  : () => import('@/components/layout/AppSidebar'),
      'AppLoader'   : () => import('@/components/layout/AppLoader'),
      'AppNotifBar' : () => import('@/components/layout/AppNotifBar'),
    },
    watch : {
      subscription(val) {
        this.notif.show = (val.trial === 1) ? true : false;

        if (val.trial === 1) {
          const currDate = Date.parse(moment.utc().local()
            .format('YYYY-MM-DD'));
          const trialDate = Date.parse(moment.utc(this.subscription.trial_end)
            .local().format('YYYY-MM-DD'));

          const diff = trialDate - currDate;
          const days = Math.floor(diff / (1000 * 60 * 60 * 24));

          if (days > 1)
            this.notif.content = days + ' TRIAL DAYS REMAINING';
          else if (days == 1)
            this.notif.content = days + ' TRIAL DAY REMAINING';
          else
            this.notif.content = 'TRIAL EXPIRING TODAY';
        }
      },
      '$route'() {
        this.checkRoutes();
      },
      profile(val) {
        if (val) {
          this.$store.dispatch('profile/getVideoFavorites');
          this.$store.dispatch('profile/getMusicFavorites');
        }
      },
    },
    methods : {

      /**
       * Parent's Pin Modal
       */
      showParentsPinModal() {
        this.$modal.show(ParentsPinModal, {
          action : 'add',
        }, {
          height       : 'auto',
          adaptive     : true,
          clickToClose : false,
        }, {
          'before-close' : () => {
            this.$store.dispatch('user/getUpdatedUser');
            this.showWelcomeVideoModal();
          },
        });
      },

      /**
       * Open Welcome Video Modal
       */
      showWelcomeVideoModal() {
        this.$modal.show(WelcomeVideoModal, {}, {
          height       : 'auto',
          width        : 800,
          adaptive     : true,
          clickToClose : false,
          classes      : ['welcome-video-modal'],
        }, {
          'before-close' : () => {
            this.updateFirstLogin();
            this.startTour = true;
          },
        });
      },

      /**
       * Update First Login
       */
      async updateFirstLogin() {
        if (this.$store.getters['user/user'].first_login === 0) {
          await this.$http.put('api/user', {
            'updateFields' : {
              // eslint-disable-next-line camelcase
              first_login : 1,
            },
          }).then(() => {
            this.$store.dispatch('user/getUpdatedUser');
          });
        }
      },

      /**
       * Open Switch User
       */
      openSwitchUser() {
        this.$modal.show(SwitchUserModal, {}, {
          height   : '100%',
          width    : '100%',
          adaptive : true,
          classes  : ['switch-user-modal'],
        }, {
          'reload-page' : () => {
            this.loading = true;
            this.$nextTick(() => {
              if (this.$route.path !== '/dashboard') {
                this.loading = false;

                if (this.user.user_type !== 6 &&
                  this.$route.path !== '/subscription')
                  this.$router.replace('/dashboard');
                else
                  window.location.reload();
              } else
                window.location.reload();
            });
          },
        });
      },

      /**
       * Check Routes
       */
      checkRoutes() {
        if (!this.$route.meta.requiresPinVer &&
          this.$route.path !== '/plans') {
          // Set Pin Ver to false once user access
          //  routes that does not require pin verification
          this.$store.dispatch('auth/setPinVer', false);
        }

        if (this.$route.meta.requiresPinVer && this.user.user_type !== 6 &&
          this.$route.path === '/subscription')
          this.checkPin();
      },

      /**
       * Check Pin Code
       */
      checkPin() {
        this.$modal.show(ParentsPinModal, {
          action   : 'check',
          redirect : false,
        }, {
          height       : 'auto',
          adaptive     : true,
          clickToClose : false,
        }, {
          'pin-correct' : () => {
            this.$store.dispatch('auth/setPinVer', true);
          },
        });
      },
    },
    mounted() {
      this.checkRoutes();
      const user = this.$store.getters['user/user'];

      /**
       * User Profile
       */

      // Set profile for underage users so that Switch User modal
      //   will not show up after successful login
      if (user.user_type === 1)
        localStorage.profile = localStorage.getItem('user');

      if (localStorage.getItem('profile')) {
        const profile = JSON.parse(localStorage.getItem('profile'));
        if (profile.user_profiles_id)
          this.$store.dispatch('profile/getInfo', profile.user_profiles_id);
        else
          this.$store.dispatch('profile/getInfo');

        this.$store.dispatch('profile/getUserContents', 1);
        this.$store.dispatch('profile/getMusicFavorites');
        this.$store.dispatch('profile/getVideoFavorites');
      }

      /**
       * Getting Started
       */
      if (user.user_type == 2 || user.user_type == 3 || user.user_type == 4) {
        if (!localStorage.getItem('profile'))
          this.openSwitchUser();

        else if (user.is_pin_set === false)
          this.showParentsPinModal();
        
        else if (user.first_login === 0)
          this.showWelcomeVideoModal();
        
        else if (user.first_photo_update === 0)
          this.startTour = true;

        // if (!localStorage.getItem('profile'))
        //   this.openSwitchUser();

        // else if (user.is_pin_set === false)
        //   this.showParentsPinModal();
        
        // else if (user.first_login === 0 && user.is_pin_set === true)
        //   this.showWelcomeVideoModal();
        
        // else if (user.first_photo_update === 0 && user.is_pin_set === true &&
        //   user.first_login === 1)
        //   this.startTour = true;
      } else if (user.user_type == 1) {
        if (user.first_login === 0)
          this.showWelcomeVideoModal();
        else if (user.first_photo_update === 0 && user.first_login === 1)
          this.startTour = true;
      }

      /**
       * Dispatch Store Actions
       */
      this.$store.dispatch('categories/getCharactersMedia');
      this.$store.dispatch('interactive/getInteractiveCategories');
      this.$store.dispatch('staticAsset/getDefaultAvatars');
      this.$store.dispatch('playlist/getPlaylistBanners');
      this.$store.dispatch('subscription/getPlans');
      this.$store.dispatch('subscription/getSubscription');

      if (user.subscription.class_name === 'school-district') {
        this.$store.dispatch(
          'subscription/getSubscriptionUsers',
          this.$store.getters['subscription/subscription'].id);
      }

      if (user.subscription.class_name !== 'free') {
        if (user.subscription.platform === 'stripe') {
          this.$store.dispatch(
            'subscription/getStripePayment',
            user.subscription.stripe_payment_method_id);
        } else {
          this.$store.dispatch(
            'subscription/getPaypalSubscription',
            user.subscription.paypal_subscription_id);
        }
      }
    },
  }
</script>

<style lang="scss" scoped>
  /* Fade */
  .component-fade-enter-active, .component-fade-leave-active {
    transition: opacity .3s ease;
  }

  .component-fade-enter, .component-fade-leave-to
    /* .component-fade-leave-active below version 2.1.8 */
  {
    opacity: 0;
  }
</style>