<template>
  <div class="switch-user-modal">
    <div class="content">
      <h3>SWITCH USER</h3>
      <b-row class="users mt-5 mb-5" align-v="start" align-h="center">
        <b-col
          class="user"
          cols="6"
          lg="3"
          @click="switchUser('main')">
          <div class="profile-container">
            <img
              class="img-fluid user-photo"
              :src="mainUser.profile_img_url"
              :alt="mainUser.profile_name"
              v-if="mainUser.profile_img_url"
            />
            <img
              class="img-fluid user-photo"
              src="../../assets/images/sample_user_profile.png"
              alt="Healthy Hip Hop Profile Photo"
              v-else
            />
          </div>
          <p class="name">{{ mainUser.profile_name | truncate(20) }}</p>
        </b-col>
        <b-col
          class="user"
          :class="{'disabled' : !allowSwitch}"
          cols="6"
          lg="3"
          v-for="(user, index) in users"
          :key="index"
          @click="switchUser(user)">
          <div class="profile-container">
            <b-overlay
              :show="!allowSwitch"
              variant="dark"
              :opacity="0.5"
              rounded="lg"
              title="Unable to Switch User">
              <template #overlay>
                <i class="fas fa-lock" style="color: transparent;"></i>
              </template>
              <img
                class="img-fluid user-photo"
                :src="user.profile_img_url"
                :alt="user.profile_name"
              />
            </b-overlay>
          </div>
          <p class="name">{{ user.profile_name | truncate(20) }}</p>
        </b-col>
        <b-col
          class="user"
          cols="6"
          lg="3"
          @click="openPinModal"
          v-if="allowAddUser">
          <div class="profile-container">
            <img
              class="img-fluid user-photo"
              src="../../assets/images/add_user_thumb.png"
              alt="Add User Profile"
            />
          </div>
          <p class="name">Add User</p>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-button
            class="btn-h3-red"
            @click="logout">
            Logout
          </b-button>
        </b-col>
      </b-row>
    </div>
    
    <!-- Modal -->
    <modals-container />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import PinModal from '@/components/pincode/PinModal';
  import AddUserProfile from '@/pages/ManageProfilesPage/AddUserProfile';
  import FilterMixin from '@/mixins/FilterMixin';
  import PremiumMixin from '@/mixins/PremiumMixin';

  export default {
    mixins : [
      FilterMixin,
      PremiumMixin,
    ],
    computed : {
      ...mapGetters({
        users        : 'subscription/userProfiles',
        subscription : 'subscription/subscription',
        profile      : 'profile/info',
      }),
      mainUser() {
        const user = this.$store.getters['user/user'];
        return {
          'profile_name'    : user.nickname,
          'profile_img_url' : user.profile_img_url,
          'user_id'         : user.user_id,
        };
      },
      userLimit() {
        const subscription = this.$store.getters['subscription/subscription'];
      
        return parseInt(subscription.user_limit) - 1;
      },
      allowAddUser() {
        let allowed = false;
        if (this.userLimit > this.users.length &&
          !this.profile.user_profiles_id)
          allowed = true;

        return allowed;
      },
      allowSwitch() {
        // let allowed = false;
        // if (this.$store.getters['subscription/subscription'].class_name !==
        //   'free')
        //   allowed = true;
          
        return true;
      },
    },
    methods : {

      /**
       * Pin Modal
       */
      openPinModal() {
        this.$modal.show(PinModal, {
          action   : 'check',
          redirect : false,
        }, {
          height   : 'auto',
          adaptive : true,
        }, {
          'pin-correct' : () => {
            this.openAddUser();
          },
        });
      },

      /**
       * Add User Modal
       */
      openAddUser() {
        this.$modal.show(AddUserProfile, {}, {
          height   : 'auto',
          width    : '800px',
          adaptive : true,
        }, {
          'update-users' : () => {
            this.$store.dispatch('subscription/getUserProfiles');
          },
        });
      },

      /**
       * Switch User
       * @param profile
       */
      switchUser(data) {
        if (data === 'main') {
          // Switch to Main User
          localStorage.profile = localStorage.getItem('user');
          this.$store.dispatch('profile/getInfo');

          this.$parent.$emit('reload-page');
          this.$emit('close');
        } else {
          // Switch to User profile
          localStorage.profile = JSON.stringify(data);
          this.$store.dispatch('profile/getInfo', data.user_profiles_id);
          
          this.$parent.$emit('reload-page');
          this.$emit('close');

          // IF SUBSCRIPTION IS ENABLED
          // if (this.subscription.class_name === 'free')
          //   this.showPremiumModal('feature');
          // else {
          //   // Switch to User profile
          //   localStorage.profile = JSON.stringify(data);
          //   this.$store.dispatch('profile/getInfo', data.user_profiles_id);
            
          //   this.$parent.$emit('reload-page');
          //   this.$emit('close');
          // }
        }
      },

      /**
       * Logout
       */
      logout() {
        this.$emit('close');
        this.$router.replace('/logout');
      },
    },
    mounted() {
      this.$store.dispatch('subscription/getUserProfiles');
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/settings";

  .switch-user-modal {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-color: rgba(0,0,0,0.75);
    text-align: center;
  }

  .content {
    width: 100%;
  }

  h3 {
    color: #fff;
    font-family: "WickedMouse" !important;
    font-size: 2.5em;
  }

  .profile-container {
    margin: 0 auto;
    width: auto;
    height: 250px;
    max-width: 250px;
    max-height: 250px;
    background-color: rgba(255,255,255,0.5);
    border-radius: 0.5rem;
  }

  .users {
    margin: 0 auto;
    width: 70%;
  }

  .user-photo {
    width: 100%;
    height: 100%;
    background-image: url(../../assets/images/user-profile-bg.png);
    background-size: contain;
    border: 3px solid #a9902c;
    border-radius: 0.5rem;
  }

  .user {
    &:hover {
      cursor: pointer;
      .user-photo {
        border: 3px solid #fff;
        border-radius: 0.5rem;
      }
      .name {
        color: #fff;
      }
    }
  }

  .disabled {
    cursor: pointer;
    .user-photo {
      border: 3px solid #fff;
      border-radius: 0.5rem;
    }
    .name {
      color: #999C9F !important;
    }
  }

  .name {
    margin: 0.5rem 0;
    color:grey;
    font-family: "WickedMouse" !important;
    font-size: 1.5em !important;
  }

  /* Small devices (portrait tablets and large phones) */
  @media only screen and (max-width: $xs-max) {
    h3 { font-size: 2em; }
    .profile-container {
      height: auto !important;
    }
  }

  /* Small devices (portrait tablets and large phones) */
  @media only screen and (min-width: $sm-min) {
    .profile-container {
      height: auto !important;
    }
  }

  /* Medium devices (landscape tablets) */
  @media only screen and (min-width: $md-min) {
    .profile-container {
      height: auto !important;
    }
  }

  /* Large devices (laptops/desktops) */
  @media only screen and (min-width: $lg-min) {
    .profile-container {
      height: auto !important;
    }
  }

  /* Extra large devices (large laptops and desktops) */
  @media only screen and (min-width: $xl-min) {
    .profile-container {
      height: auto !important;
    }
  }

  @media only screen and (min-width: $special) {
    .profile-container {
      width: 150px;
      height: auto !important;
    }
  }

  @media only screen and (min-width: $xl2-min) {
    .profile-container {
      width: 250px;
      height: 250px !important;
    }
  }

  /* iMac Res */
  @media only screen and (min-width: $xl5-min) {
    .profile-container {
      width: 250px;
      height: 250px !important;
    }
  }
</style>